<template>
  <div id="vehicleDeviation">
    <div class="table-container">
      <el-table
          :data="tableData"
          style="width: 100%"
          v-loading="loading"
      >
        <el-table-column prop="plateNum" label="车牌号" align="center">
        </el-table-column>
        <el-table-column prop="vehicleDiff" label="车辆差异" align="center">
        </el-table-column>
        <el-table-column prop="dateChanged" label="发生时间" align="center">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            layout="prev, pager, next"
            background
            :total="total"
            :page-size="pageSize"
            :current-page.sync="page"
            @current-change="getList"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
      return {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 5,
        loading: false,
      }
  },
  created() {
    this.getList();
  },
  methods:{
    async getList(page = this.page) {
      this.page=page
      try {
        this.loading = true;
        let res = await this.$http.post("/statistics/vehicle/diff", {
          pageNum: page,
          pageSize: this.pageSize
        });
        if (res.code === 0) {
          for(let item of res.data.list){
            item.dateChanged = this.$moment(item.dateChanged).format("YYYY-MM-DD")
          }
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss">
#vehicleDeviation {
  .table-container{
    padding: 0 !important;
    .el-pagination{
      padding-right: 0;
      .btn-next{
        margin-right: 0;
      }
    }
  }
}
</style>
